import { cartPromotionMapHandle } from 'public/src/pages/cartNew/utils/promotionMapHandle.js'
import { htmlDecode, template } from '@shein/common-function'
import couponAddon from '../../../../cartNew/components/coupon/cartCouponAddonGetter'
import { getTipTexts } from 'public/src/pages/common/addOnItem/layout/AllFullDiscount/utils.js'
import { sumBy } from 'lodash'
import { getAddOnItemStatus } from 'public/src/pages/cartNew/utils/index.js'
import { isObject } from '@shein/common-function'
import { isFreeShipCoupon } from 'public/src/pages/cartNew/utils/freeShipping.js'
import newFreeShippingInfo from './getters/new-free-shipping-info.js'

const localHtmlDecode = (msg) => {
  if ( typeof window != 'undefined' ) {
    return htmlDecode({ text: msg })
  }
  return msg
}

const ActivityTypeLimit = {
  '1': 'free_activity_limit',
  '2': 'free_policy_limit',
  '3': 'freight_activity_limit',
}

const handleShippingAnalysisParam = (shippingInfoAvailable = {}, totalPriceAmount = 0) =>{
  const { id, next_shipping_price, charging_type, shipping_detail_obj, max_shipping_price, originPrice, is_free_shipping, diff } = shippingInfoAvailable
  const analysisParam = {}
  const amount = totalPriceAmount

  //免邮运费
  if (originPrice && originPrice.amount) {
    analysisParam.origin_price = originPrice && originPrice.amount
    analysisParam.is_fullshippingactivity = is_free_shipping == 1 ? 1 : 0
  }

  //阶梯运费
  if (shipping_detail_obj) {
    let nowPrice = 0
    for (let i in shipping_detail_obj) {
      let info = shipping_detail_obj[i]
      if (+amount > +info.num && info.num_price) {
        nowPrice = info.num_price.amount
      }
    }
    analysisParam.step_fee = max_shipping_price.amount
    analysisParam.charging_type = charging_type
    analysisParam.num_price = nowPrice
    analysisParam.is_fullshippingactivity = diff <= 0 && !next_shipping_price ? 1 : 0
  }
  analysisParam.shipping_method = id

  return analysisParam
}

const getFreeShippingProgressRate = (shippingInfoAvailable = {}) => {
  const { diff_price = {}, origin_price = {} } = shippingInfoAvailable
  if (!diff_price.amount || !origin_price.amount) return 0
  if (diff_price.amount <= 0) return 100
  return 100 - (diff_price.amount / origin_price.amount) * 100
}

const getters = {
  ...newFreeShippingInfo,
  mallShippingAnalysisParam (state) {
    let mallShippingAnalysisParam = {
      platform: null, // 平台级免邮
    }
    const { cartInfo = {}, mallShippingInfo = {} } = state
    for(let key in mallShippingInfo){
      let amount = 0
      let shipping_free_type = 1
      if(key == 'platform') {
        amount = cartInfo.totalPrice?.amount
        shipping_free_type = 0
      } else {
        amount = cartInfo.mall_list.find(item => item.mall_code == key)?.total_price?.amount
        shipping_free_type = 3
      }
      if(!mallShippingInfo[key].isFirstFreeShipping && mallShippingInfo[key].shippingInfoAvailable) {
        mallShippingAnalysisParam[key] = handleShippingAnalysisParam(mallShippingInfo[key].shippingInfoAvailable, amount)
        mallShippingAnalysisParam[key].shipping_free_type = shipping_free_type
      } else {
        mallShippingAnalysisParam[key] = null
      }
    }
    return mallShippingAnalysisParam
  },
  // listSort(state){
  //   return state.cartList[0] && state.cartList[0].business_model == 1 ? ['localCartList', 'selfCartList'] : ['selfCartList', 'localCartList']
  // },
  // mall标签 1：兜底、2：平台、3：自营、4：跨境、5：本土，逗号分隔
  // 任意一个MALL的标签mall_tags中，有“平台”标签，则在该站点不隐藏SHEIN店铺组头
  showSheinShop (state) {
    return state.isPlatForm && state.siteMallList.find(item => item.mall_tags?.split(',').includes('2')) ? true : false
  },
  /**
   * priority:优先级 1、倒计时活动加需要凑单-》2、需要凑单 -》3、倒计时-》4、普通
   * 包含活动：全场满减、 全场满折、全场返券
  */
  mallFullCourtMap(state){
    let mallMap = {}
    let singleMallFullPromotionList = Object.values(state.integrityPromotionInfo).filter(promo => ([21, 26].includes(+promo.type_id) || ([14].includes(+promo.type_id) && promo.goods?.type == 3)))
    singleMallFullPromotionList.forEach(item => {
      if(!mallMap[item.mall_code]){
        mallMap[item.mall_code] = []
      }
      // 全场返券进度条
      let processCount = 0
      if (item.type_id == 21) {
        const diffPrice = +item?.promotion_range_info?.diff?.value || 0
        if (!diffPrice) {
          processCount = 100
        } else {
          const totalPrice = +state.cartInfo?.mall_list?.find(val => val.mall_code == item.mall_code)?.total_price?.amount || 0
          processCount = parseInt((1 - (diffPrice / (diffPrice + totalPrice))) * 100)
        }
      }

      const fullCourtItem = {
        promotion_id: item.promotion_id,
        typeId: item.type_id,
        tip: item.content?.tip || '',
        range: item?.promotion_range_info?.range,
        countDownTimeStamp: +item.is_count_down ? item.end_time : '',
        add: item.content?.action,
        priority: +item.is_count_down && item.content?.action ? 1 : item.content?.action ? 2 : +item.is_count_down ? 3 : 4,
        icon: state.locals?.IS_RW ? 'suiiconfont sui_icon_activity_24px' : 'iconfont icon-Rectangle-pathx1',
        title: item.type_id == 21 ? state.language.SHEIN_KEY_PWA_20407 : state.language.SHEIN_KEY_PWA_20406,
        diff_us_amount: item.content?.diffAmount ? item.content.diffAmount.usdAmount || 0 : 0,
        diff_amount: item.content?.diffAmount ? item.content.diffAmount.amount || 0 : 0,
        processCount: processCount,
      }

      if (item.type_id == 21) {
        Object.assign(fullCourtItem, {
          couponNum: item.coupon_num,
          allFullReduceFlag: item.all_full_reduce_flag,
          totalValue: item.total_value,
          coupons: item.coupons,
          lable_id: item.lable_id, // 推荐选品id
        })
      }

      mallMap[item.mall_code].push(fullCourtItem)
    })
    // mallMap['1'] = []
    // mallMap['1'].push({
    //   promotion_id: 1111,
    //   typeId: 21,
    //   tip: 'test 21 promotion tips hahahahahahaha add on items test 21 promotion tips hahahahahahaha add on items test 21 promotion tips hahahahahahaha add on items',
    //   countDownTimeStamp: Date.now() + 10000000,
    //   add: 1,
    //   icon: state.locals?.IS_RW ? 'suiiconfont sui_icon_activity_24px' : 'iconfont icon-Rectangle-pathx1',
    //   title: state.language.SHEIN_KEY_PWA_20407
    // })
    // mallMap['1'].push({
    //   promotion_id: 2222,
    //   typeId: 26,
    //   tip: 'test 26 promotion tips hahahahahahaha return coupon',
    //   countDownTimeStamp: Date.now() + 9000000,
    //   add: 1,
    //   icon: state.locals?.IS_RW ? 'suiiconfont sui_icon_activity_24px' : 'iconfont icon-Rectangle-pathx1',
    //   title: state.language.SHEIN_KEY_PWA_20406,
    // })
    // mallMap['1'].push({
    //   promotion_id: 3333,
    //   typeId: 14,
    //   tip: 'test 14 promotion tips hahahahahahaha full substrict',
    //   countDownTimeStamp: Date.now() + 8000000,
    //   add: 1,
    //   icon: state.locals?.IS_RW ? 'suiiconfont sui_icon_activity_24px' : 'iconfont icon-Rectangle-pathx1',
    //   title: state.language.SHEIN_KEY_PWA_20406,
    // })
    Object.keys(mallMap).forEach(key => mallMap[key].sort((a, b)=> a.priority - b.priority))
    return mallMap
  },
  addOnPromotionList(state, getters) {
    const promotionIds = [
      20, 21, 14, 26, 25, 9, 1, 15,
      4, 28, 2, 22, 13 // 附属品活动id
    ]
    const { integrityPromotionInfo } = state
    let res = []
    Object.values(integrityPromotionInfo).filter(promo => promotionIds.includes(+promo.type_id)).forEach(item => {
      let courtItem = {}

      // 订单返券
      if ([20, 21].includes(+item.type_id)) {
        const { promotion_range_info, rules } = item
        const { range, next, diff = {} } = promotion_range_info
        // next==0为满足最高档
        const nowRange = rules[next == 0 ? range - 1 : range]
        const diffPrice = +diff?.value || 0
        // 计算返券活动进度
        const processCount = parseInt((1 - (diffPrice / +nowRange?.value)) * 100)

        courtItem = {
          promotion_id: item.promotion_id,
          typeId: item.type_id,
          tip: item.content?.tip || '',
          range: item?.promotion_range_info?.range,
          countDownTimeStamp: +item.is_count_down ? item.end_time : '',
          isCountDown: !!item.is_count_down,
          diffAmount: item.content?.diffAmount || {},
          mall_code: item.mall_code,

          addOnType: 'returnCoupon',
          couponNum: item.coupon_num,
          allFullReduceFlag: item.all_full_reduce_flag,
          totalValue: item.total_value,
          coupons: item.coupons,
          lable_id: item.lable_id, // 推荐选品id
          promotionRangeInfo: item?.promotion_range_info,
          rules: item?.rules,
          processCount,
        }
      }

      // 附属品凑单
      if ([4, 28, 2, 22, 13].includes(+item.type_id)) {
        const { isPicked, isChecked } = getAddOnItemStatus(item, getters.cartList, state.soldoutIds)

        courtItem = {
          addOnType: 'accessory',
          isPicked,
          cartTips: isObject(item.content?.tip) ? item.content?.tip[isChecked ? 1 : 0] : item.content?.tip,
          ...item,
        }
      }

      if ([1, 9, 25, 26].includes(Number(item.type_id || 0))) courtItem = {
        addOnType: 'discount',
        addOnTips: getTipTexts(item, state.language),
        ...item,
      }
      if(item.type_id == 14) courtItem = {
        addOnType: 'reduce',
        addOnTips: getTipTexts(item, state.language),
        ...item,
      }
      // x元n件
      if(item.type_id == 15) courtItem = {
        addOnType: 'bundle',
        addOnTips: getTipTexts(item, state.language),
        ...item,
      }
      res.push(courtItem)
    })
    // 免邮活动凑单弹窗
    let freeShippingInfo = {}
    if (state.isMoreMallFreeShippingAddOn) {
      freeShippingInfo = state.mallShippingInfo[state.freeShippingAddOnType] || {}
    } else {
      freeShippingInfo = getters.topShippingInfo
    }
    res.push({
      promotion_id: getters.firstFreeShipping.firstFreeShippingTip ? 998 : 999,
      addOnType: 'freeShipping',
      tip: freeShippingInfo.isFirstFreeShipping ? freeShippingInfo.firstFreeShipping.firstFreeShippingTip : freeShippingInfo.shippingTip,
      showCountDown: getters.firstFreeShipping?.showCountDown, // 是否展示倒计时，目前只有首单免邮有倒计时
      countDownTimeStamp: getters.firstFreeShipping?.endTimestamp, // 倒计时结束时间,
      progressRate: getFreeShippingProgressRate(freeShippingInfo.shippingInfoAvailable || {}), // 进度条进度
      isShowProgress: freeShippingInfo.shippingInfoAvailable?.diff_price && freeShippingInfo.shippingInfoAvailable?.origin_price, // 是否展示进度条，目前运费活动返回的字段无法算出进度条进度，所不展示
      activityId: freeShippingInfo.shippingInfoAvailable?.activity_id || 0, // 活动id，运费活动没有该字段
      activityType: freeShippingInfo.shippingInfoAvailable?.activity_type || 0, // 活动类型，运费活动是3 免邮活动是1
      addOnGoodsInfoList: freeShippingInfo.shippingInfoAvailable?.add_on_goods_info_list || [], // 商品池
      isAmountType: !freeShippingInfo.shippingInfoAvailable?.charging_type || freeShippingInfo.shippingInfoAvailable?.charging_type == 3, // 计价方式是否是金额类型，运费活动可能不是金额类型
      diffPrice: freeShippingInfo.shippingInfoAvailable?.diff_price || {}, // 差额
      shippingMethodName: freeShippingInfo.shippingInfoAvailable?.shippingMethodName || '', // 运费活动名称
      nowLevel: freeShippingInfo.shippingInfoAvailable?.now_level || 0, // 当前档位
      mall_code: state.freeShippingAddOnType === 'platform' ? '' : state.freeShippingAddOnType, // mall_code
    })
    
    return res
  },
  freeShipAddType(state) {
    let shipInfo = state.mallShippingInfo[state.freeShippingAddOnType] || {}
    if(shipInfo.isFirstFreeShipping) return 3
    return shipInfo.activity_type > 0 ? [ '', 0, 1, 4 ][shipInfo.activity_type] : ''
  },
  // 获取单mall的mallcode
  singleMallCode(state, getters){
    if(!state.isPlatForm){
      return getters.cartList.length ? getters.cartList[0].mall_code : ''
    }
    return ''
  },
  fullCourtMap(state){
    let map = []
    let fullPromotionList = Object.values(state.integrityPromotionInfo).filter(promo => [21, 26].includes(+promo.type_id) || ([14].includes(+promo.type_id) && promo.goods?.type == 3))
    fullPromotionList.forEach(item => {
      map.push({
        promotion_id: item.promotion_id,
        typeId: item.type_id,
        tip: item.content?.tip || '',
        countDownTimeStamp: +item.is_count_down ? item.end_time : '',
        add: item.content?.action,
        priority: +item.is_count_down && item.content?.action ? 1 : item.content?.action ? 2 : +item.is_count_down ? 3 : 4,
        icon: state.locals?.IS_RW ? 'suiiconfont sui_icon_activity_24px' : 'iconfont icon-Rectangle-pathx1',
        title: item.type_id == 21 ? state.language.SHEIN_KEY_PWA_20407 : state.language.SHEIN_KEY_PWA_20406,
      })
    })
    return map.sort((a, b)=> a.priority - b.priority)
  },
  fullCourtPromotionTip (state) {
    const result = {
      /*mall_code:{
        addOnAll: {
          tip: '',
          tips: []
        },
        fullGifts: {
          tip: '',
          tips: []
        },
      }*/

    }
    const integrityPromotionInfo = state.integrityPromotionInfo
    const language = state.language

    if (!integrityPromotionInfo) return result

    Object.values(integrityPromotionInfo).forEach(item => {
      const typeId = item.type_id
      if(!result[item.mall_code]){
        result[item.mall_code] = {}
      }
      if (typeId == '22') {
        if(!result[item.mall_code].addOnAll){
          result[item.mall_code].addOnAll = {
            tip: '',
            tips: [],
            diff_us_amount: [] //美元差价
          }
        }
        const { range, all_diff } = item.promotion_range_info
        const { rules } = item
        result[item.mall_code].addOnAll.tips = []

        rules.forEach((i, index) => {
          const { type, value, value_amount } = all_diff[index]
          const difference = type == 1 ? value : value_amount?.amountWithSymbol
          const checkDifference = type == 1 ? value : Number(value_amount?.usdAmount)

          const rangeListAmount = i.value
          const rangeListAmountWithSymbol = i.value_amount?.amountWithSymbol
          const addOnAllTip = checkDifference ?
            (i.type == 1 ?
              template(`<em>${rangeListAmount}</em>`, `<em>${difference}</em>`, localHtmlDecode(language.SHEIN_KEY_PWA_16834))
              :
              template(`<em>${rangeListAmountWithSymbol}</em>`, `<em>${difference}</em>`, localHtmlDecode(language.SHEIN_KEY_PWA_16833)))
            : localHtmlDecode(language.SHEIN_KEY_PWA_14858)
          if (index == 0) {
            if (range <= 0) {
              result[item.mall_code].addOnAll.tip = addOnAllTip
            } else {
              result[item.mall_code].addOnAll.tip = localHtmlDecode(language.SHEIN_KEY_PWA_14858)
            }
          }
          result[item.mall_code].addOnAll.tips.push(addOnAllTip)
          result[item.mall_code].addOnAll.diff_us_amount.push(type == 1 ? 0 : Number(value_amount?.usdAmount || 0 ))
        })
      } else if (typeId == '4') {
        if(!result[item.mall_code].fullGifts){
          result[item.mall_code].fullGifts = {
            tip: '',
            tips: [],
            diff_us_amount: [] //美元差价
          }
        }
        const { all_diff } = item.promotion_range_info
        result[item.mall_code].fullGifts.tips = []
        all_diff.forEach((i, index) => {
          const { type, value, value_amount } = all_diff[index]
          const difference = type == 1 ? value : value_amount?.amountWithSymbol
          let fullGiftsTip = ''

          if ((type == 1 && value == 0) || (type != 1 && value_amount?.usdAmount == 0)) {
            fullGiftsTip = localHtmlDecode(language.SHEIN_KEY_PWA_17978)
          } else {
            fullGiftsTip = template(`<em>${difference}</em>`, localHtmlDecode(language.SHEIN_KEY_PWA_18966))
          }

          if (index == 0) {
            result[item.mall_code].fullGifts.tip = fullGiftsTip
          }
          result[item.mall_code].fullGifts.tips.push(fullGiftsTip)
          result[item.mall_code].fullGifts.diff_us_amount.push(type == 1 ? 0 : Number(value_amount?.usdAmount || 0 ))
        })
      }
    })
    return result
  },
  cartPromotionMap(state){
    return cartPromotionMapHandle({ integrityPromotionInfo: state.integrityPromotionInfo }) // 判断活动是否满足 埋点用
  },
  cartCheckoutPromotionMap(state, getters){
    return cartPromotionMapHandle({ integrityPromotionInfo: state.integrityPromotionInfo, checkedCartPromotionIds: getters.checkedCartPromotionIds }) // 判断活动是否满足 埋点用
  },
  // 用户限购 TODO 不存在的字段，先静态化，无影响再处理逻辑
  memberLimit () {
    // const cartInfo = state.cartInfo || {}
    const result = {
      goods: [],
      times: ''
    }
    return result
    // if (cartInfo.member_limit_goods) result.goods = cartInfo.member_limit_goods
    // if (cartInfo.member_limit) result.times = cartInfo.member_limit

    // return result
  },
  // 顶部展示免邮信息：多mall展示平台级、单mall展示mall级
  topShippingInfo (state, getters) {
    if(state.isPlatForm) {
      return state.mallShippingInfo[`platform`] || {}
    }
    return getters.singleMallCode ? state.mallShippingInfo[getters.singleMallCode] || {} : {}
  },
  firstFreeShipping (state, getters){
    return getters.topShippingInfo.firstFreeShipping || {}
  },
  satisfyFreeShipping(state, getters) {
    const { isPlatForm, mallList, mallShippingInfo } = state
    const { topShippingInfo, firstFreeShipping } = getters
    if (
      topShippingInfo.showShipSupplement == false &&
      (
        (topShippingInfo.isFirstFreeShipping && firstFreeShipping.firstFreeShippingTip) ||
        (!topShippingInfo.isFirstFreeShipping && topShippingInfo.shippingTip )
      )
    ) {
      return true
    }

    if (isPlatForm) {
      // mall级
      let flag = 0
      mallList.forEach(mall => {
        let mall_shipping = mallShippingInfo[mall.mall_code]
        if(mall_shipping && mall_shipping?.showShipSupplement == false){
          flag++
        }
      })
      return flag == mallList.length
    }

    return false
  },
  flashSaleGroupList(state, getters) {
    let result = {}
    getters.cartList.filter(item => item.promotionTypeIds?.some(i => [10, 11].includes(i))).forEach(item => {
      // 默认10普通闪购不会与11直播闪购同时存在于同一商品上
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => [10, 11].includes(promotion.type_id))
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  showQuickShipTag(state){
    return !state.isPlatForm  && state.cartAbtInfo['TradeQuickship'] && state.cartAbtInfo['TradeQuickship'].param == 'show_QuickShipping' ? true : false
  },
  /**
   * wiki.pageId=1004875731
   * is_has_quota 付费会员额度是否受限 1-有限制 0-不限制
   */
  isHasQuota(state, getters){
    return getters.promotionGrantAmountInfo.is_has_quota == 1
  },
  /**
   * wiki.pageId=1021332568
   * vip_sum_over 付费会员额度超限标识  1-超限 0-不超限
   * @return {boolean}
   */
  vipSumOver(state, getters){
    return getters.promotionGrantAmountInfo.vip_sum_over == 1
  },
  promotionGrantAmountInfo (state) {
    return state.cartInfo && state.cartInfo.promotion_grant_amount_info || {
      // 'cycle_grant_amount': {
      //   'amount': '200',
      //   'amountWithSymbol': '$300',
      // },
      // 'cycle_used_grant_amount': {
      //   'amount': '23',
      //   'amountWithSymbol': '$330',
      // },
      // 'vip_sum_over': 1,
      // is_has_quota: 1
    }
  },
  primeVipOverQuotaTip(state, getters){
    const { cycle_used_grant_amount, cycle_grant_amount } = getters.promotionGrantAmountInfo
    return getters.isHasQuota && cycle_used_grant_amount?.amountWithSymbol && cycle_grant_amount?.amountWithSymbol ? template(cycle_used_grant_amount.amountWithSymbol, cycle_grant_amount.amountWithSymbol, state.language.SHEIN_KEY_PWA_22688) : ''
  },
  showDiscountlistshowlimitexceededtipAbt(state){
    return state.cartAbtInfo['Discountlistshowlimitexceededtip']?.param === 'off' ? false : true
  },
  showPrimeVipOverQuota(state, getters){
    return getters.vipSumOver && getters.primeVipOverQuotaTip && getters.showDiscountlistshowlimitexceededtipAbt
  },
  // 闪购超限展示形式
  flashSaleOverShowType () {
    return 'toast'
  },
  underPriceGroupList(state, getters) {
    let result = {}
    getters.cartList.filter(item => item.promotionTypeIds?.includes(30)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 30)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  underPriceAbtValue (state) {
    return state.cartAbtInfo.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  checkedCartPromotionIds(state, getters) {
    let cartPromotionIds = []
    getters.cartList.filter(item => item.is_checked).forEach(cartItem => {
      cartPromotionIds.push(...cartItem.promotionIds)
    })
    let cartPromotionIdsSet = new Set(cartPromotionIds)
    cartPromotionIds = [...cartPromotionIdsSet]
    return cartPromotionIds
  },
  showCartNotEligibleForCoupons(state){
    return state.cartAbtInfo?.Cartnoteligibleforcoupons?.param == 'on' ? true : false
  },
  /**
   * 负向信息聚合（不可退商品、不可参与积分活动商品、不可参与包邮商品、不可参与优惠券的商品）
   * @description 不可参与优惠券提示满足3条件： 1、自营商品 2、have_tag为1命中 3、abt Cartnoteligibleforcoupons返回on
   * return {} {mall_code: {noReturn:{ title: 'xxx', list: []},noPoint:{ title: 'xxx', list: []},noFreeShipping:{ title: 'xxx', list: []},noCoupon:{ title: 'xxx', list: []}}}
   */
  negativeInformation(state, getters){
    let platFormActivityType = ''
    if(state.isPlatForm){
      platFormActivityType =  state.mallShippingInfo[`platform`]?.activity_type || ''
    }
    let negativeInfo = {}
    let normalCarts = getters.cartList.filter(item => !state.soldoutIds.includes(item.id))

    const negativeInfoTypeHandle = ({ cartItem, type, title }) => {
      if(!negativeInfo[cartItem.mall_code]) negativeInfo[cartItem.mall_code] = {}
      if(!negativeInfo[cartItem.mall_code][type])  {
        negativeInfo[cartItem.mall_code][type] = {
          title: title,
          list: []
        }
      }
      negativeInfo[cartItem.mall_code][type].list.push(cartItem)
    }

    normalCarts.forEach(cartItem => {
      if(cartItem.product.double_status == 0) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noPoint', title: state.language.SHEIN_KEY_PWA_21240 })
      }
      if (cartItem.store_type == 2 && cartItem.product.have_tag == 1 && getters.showCartNotEligibleForCoupons) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noCoupon', title: state.language.SHEIN_KEY_PWA_22568 })
      }
      
      if(platFormActivityType && ActivityTypeLimit[platFormActivityType] && cartItem[ActivityTypeLimit[platFormActivityType]] == 1){ // 平台级物流信息
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noFreeShipping', title: state.language.SHEIN_KEY_PWA_18641 })
      } else { // mall级物流信息
        let mallActivityType = state.mallShippingInfo[cartItem.mall_code]?.activity_type || ''
        if(mallActivityType && ActivityTypeLimit[mallActivityType] && cartItem[ActivityTypeLimit[mallActivityType]] == 1){
          negativeInfoTypeHandle({ cartItem: cartItem, type: 'noFreeShipping', title: state.language.SHEIN_KEY_PWA_18641 })
        }
      }

      if(!getters.isNewReturnFlag && cartItem.return_flag && cartItem.return_flag != 0) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noReturn', title: state.language.SHEIN_KEY_PWA_15856 })
      }
    })

    return negativeInfo
  },
  cartStoreInRow(state, getters){ // 显示店铺且不分组，展示在商品行
    return getters.cartShowStore && state.cartAbtInfo.Cartstoreinrow?.param?.cart_store_inrow_switch == 'on'
  },
  // 店铺新abt
  cartShowStore(state) { // 是否显示店铺
    return state.cartAbtInfo.cartShowStoreCode?.param?.cart_show_storecode === 'on'
  },
  cartShowSheinBrand(state) { // 是否新增shein品牌分组
    return state.cartAbtInfo.cartShowBrand?.param?.cart_show_brand === 'on'
  },
  iconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical-cart' : 'suiiconfont'
  },
  oldIconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical-cart' : 'iconfont'
  },
  criticalIconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical' : 'suiiconfont'
  },
  ...couponAddon, // 混入优惠券凑单聚合数据, 均以coupon开头
  doFilter(state) {
    return !state.batchActive && state.filterType != 'none'
  },
  // TODO 重复的
  cartList(state) {
    return state.cartsId.map(id => state.cartItemMap[id])
  },
  carts(state) {
    return state.cartsId.map(id => state.cartItemMap[id])
  },
  quickShipPrior(state, getters) {
    if (state.locals?.IS_RW ) return ''
    const limit = state.cartAbtInfo?.Quickshiprecommendthreshold?.param?.quickship_recommend_threshold || 0
    const productList = getters.cartList.filter(item => Boolean(item.is_checked) && item.quick_ship == 1)
    const sum = sumBy(productList, item => +(item.unitPrice?.usdAmount || 0))
    return sum > limit ? 1 : 0
  },
  // 购物车吸底活动是否展示节日样式（黑五）
  isShowCartActivityColor(state) {
    return state.cartAbtInfo?.CartActivityColor?.param?.cart_activity_color == 'on'
  },
  needFlushPrerenderCache(state) {
    return !!state.cartLeave || !state.loginStatus || !state.cartInfo.carts?.some(_ => _.is_checked == 1)
  },
  // @desc
  // wiki.pageId=1311685888
  // 单mall：当前有满足门槛的免邮指定券 or 当前站点下有一个或一个以上的运输方式已免邮
  // 多mall：当前站点下有一个或一个以上的运输方式已免邮
  checkoutFreeShippingState(state) {
    const isFreeShipping = shipping => shipping?.some(item => item?.is_free_shipping === 1)
    const mallAllFree = state?.mallShippingInfo?.mall_transport_free_list?.every(item => isFreeShipping(item.transport_free_list))
    
    // 多mall
    if (state.isPlatForm) {
      return mallAllFree
    }

    // 当前有满足门槛的免邮指定券
    const hasFreeCouponItem = state?.couponInfo?.usableCouponList?.some(item => isFreeShipCoupon(item))
    return hasFreeCouponItem || mallAllFree
  },
  // 购物车折扣角标样式是否为a方案，默认为onA，即不配置为off时都为onA，若新增其他样式方案，要按需修改此逻辑
  cartDiscountLogoA(state) {
    return state.cartAbtInfo?.Discountlogo?.param?.add_discount_logo_switch != 'off'
  },
  // 新增CartrowEstimatedCountdown, 是否展示购物车到手价标签倒计时
  isShowCartrowEstimatedCountdown(state) {
    return state.cartAbtInfo?.CartrowEstimatedCountdown?.param?.cartrow_estimated_countdown == 'on'
  },
  // 获取优惠券信息，此次需求新增节点coupon_infos
  cartCouponInfos(state) {
    return state.cartInfo?.coupon_infos || []
  },
  // 购物车筛选是否常驻置顶
  isFilterControlTop(state) {
    return state.cartAbtInfo?.cartSelect?.param?.control_top == 'on'
  },
  // 购物车筛选ui方案，off-保持线上，A-橙底橙字、icon变更，B-黑底白字
  filterControlStyle(state) {
    return state.cartAbtInfo?.cartSelect?.param?.control_style
  },
  // 购物车筛选标签样式，off-线上，logo-logo样式，text-文字样式
  filterSelectLogo(state) {
    return state.cartAbtInfo?.cartSelect?.param?.ControlSelectlogo
  },
  // 推荐的支付前置支付方式
  paymentFrontRecommendPayment(state) {
    const paymentItemList = state.paymentFrontShowForCartIndexInfo?.paymentItemList || []
    // 默认取推荐的支付方式，没有再取第一个进行兜底
    return paymentItemList.find(item => item.recommend == 1) || paymentItemList[0] || ''
  },
  // 支付前置展示样式
  payShowStyle(state) {
    return state.cartAbtInfo?.PayinCart?.param?.PayShow || 'off'
  },
  // 支付前置推荐支付方式CODE
  paymentFrontPaymentCode(state, getters) {
    return getters.payShowStyle == 'A' && getters.paymentFrontRecommendPayment?.paymentCode || ''
  },
  // 支付前置支付方式列表
  paymentFrontPaymentList(state) {
    return state.paymentFrontShowForCartIndexInfo?.paymentItemList || []
  },
  // 支付前置展示样式A
  payShowStyleA(state, getters) {
    // TODO
    // return false
    return getters.payShowStyle === 'A' && getters.paymentFrontRecommendPayment && state.paymentFrontShowForCartIndexInfo?.periodAmountFormat?.amount > 0 ? true : false
  },
  // 支付前置展示样式B
  payShowStyleB(state, getters) {
    return getters.payShowStyle === 'B' && getters.paymentFrontRecommendPayment && state.paymentFrontShowForCartIndexInfo?.periodAmountFormat?.amount > 0 ? true : false
  },
  /**
   * 购物车支持券筛选-开启券筛选或券凑单
   * select-券筛选，couponhelper-券凑单（默认）,both-共存
   * @param {*} state 
   * @returns string
   */
  couponSelectType(state) {
    return state.cartAbtInfo?.cartSelect?.param?.CouponSelectType || 'couponhelper'
  },
  /**
   * 购物车支持券筛选-开启券筛选后，展示券前价还是券后价
   * beforecouponprice-券前价，aftercouponprice-券后价（默认）
   * @param {*} state 
   * @returns string
   */
  selectPrice(state) {
    return state.cartAbtInfo?.cartSelect?.param?.SelectPrice || 'aftercouponprice'
  },
  /**
   * 购物车筛选标签展示顺序
   * flash_sale-闪购，oos-即将售罄，pricedown-降价，coupon-优惠券
   * 兜底默认'flash_sale,pricedown,oos'
   * @param {*} state 
   * @returns []
   */
  filterLabelOrder(state) {
    return state.cartAbtInfo?.cartSelect?.param?.CouponShowOrder || 'flash_sale,pricedown,oos'
  },
  // 是否开启购物车券筛选与底部feed流联动
  isCartSelectRecommend(state) {
    return state.cartAbtInfo?.cartSelect?.param?.cartSelect_recommend == 'on'
  },
  isSuggested(state) {
    return state.locals?.IS_SUGGESTED || false
  },
  // 展示预估到手价入口
  showTotalFinallyPriceEntry(state, getters) {
    if (getters.isSuggested) {
      return (+state.cartInfo?.totalPrice?.amount || 0) < (+state.cartInfo?.total_price_before_coupon?.amount || 0)
    }
    return false
  },
  // 展示促销入口
  showTotalOriginalPriceEntry(state, getters) {
    if (getters.isSuggested === 'special_de') {
      return (+state.cartInfo?.suggested_sale_price?.amount || 0) > (+state.cartInfo?.total_price_before_coupon?.amount || 0) 
    } else if (getters.isSuggested) { // special || normal
      return (+state.cartInfo?.total_price_before_coupon?.amount || 0) < (+state.cartInfo?.crossed_price?.amount || 0)
    }
    return false
  },
  // 展示折扣入口
  showTotalDiscountEntry(state, getters) {
    if (!getters.isSuggested) {
      return (+state.cartInfo?.total_discount_price?.amount || 0) > 0
    }
    return false
  },
  // 展示checkout按钮利诱文案
  incentiveBtnType(state, getters) {
    // return 'oos'
    const abtArr = state.cartAbtInfo?.IncentiveInform?.param?.IncentiveButton.split(',') || []
    const checkedCarts = getters?.carts.filter(item => item.is_checked == 1)
    for (let i = 0; i < abtArr.length; i++) {
      if (abtArr[i] === 'coupon') {
        if (state.couponFilterLabelData?.usableCouponList?.length > 0 || state.couponInfo?.usableCouponList?.length > 0) {
          return abtArr[i]
        } else {
          continue
        }
      }
      if (abtArr[i] === 'flash_sale') {
        if (checkedCarts?.some(v => v.product?.product_promotion_info?.some(p => p.type_id == 11 || (p.type_id == 10 && v.flashType == '1')))) {
          return abtArr[i]
        } else {
          continue
        }
      }
      if (abtArr[i] === 'mark_down') {
        if (checkedCarts?.some(v => v.reduce_price?.reduceAmount?.amount > 0)) {
          return abtArr[i]
        } else {
          continue
        }
      }
      if (abtArr[i] === 'oos') {
        if (checkedCarts?.some(v => v.isAlmostOosItem)) {
          return abtArr[i]
        } else {
          continue
        }
      }
    }

    return ''
  },
  incentiveTimeConfig(state, getters) {
    // TODO: read on apollo
    return {
      leftCountDownTime: 1000 * 60 * 5,
      noInteractionTimeout: 3000,
      popoverKeepTime: 3000,
      incentiveGapTime: 1000 * 60
    }
  },
  showTotalOriginalPriceEntryArrow(state, getters) {
    if (!getters.isSuggested) return false
    if (getters.isSuggested === 'special_de') {
      return !getters.showTotalFinallyPriceEntry && (+state.cartInfo?.originPrice?.amount || 0) > (+state.cartInfo?.totalPrice?.amount || 0) && (+state.cartInfo?.total_discount_price?.amount || 0) > 0
    }
    return !getters.showTotalFinallyPriceEntry && getters.showTotalOriginalPriceEntry && +state.cartInfo?.total_discount_price?.amount > 0
  },
  // 是否使用了券或者积分
  isUseCouponOrPoint(state, getters) {
    if (getters.isSuggested === 'special_de') {
      return (+state.cartInfo?.totalPrice?.amount || 0) <= (+state.cartInfo?.total_price_before_coupon?.amount || 0)
    }
    if (getters.isSuggested) {
      return (+state.cartInfo?.totalPrice?.amount || 0) < (+state.cartInfo?.total_price_before_coupon?.amount || 0)
    }
    return false
  },
  showGoshoppingBtn(state) {
    return !state.unPaidOrderInfo.orderData?.billno
  },
  // 免邮活动置顶显示，与置底互斥
  cartShippingTop(state) {
    return state.cartAbtInfo?.cartRightsFuns?.param?.cart_rights_funs === 'on' || state.cartAbtInfo?.CartShippingTopSwitch?.param?.cart_shipping_top_switch === 'on'
  },
  // 是否开启evoluSHEIN标签
  switchOnEvoluSHEIN(state) {
    return state.cartAbtInfo?.evoluSHEINShow?.param?.evoluSHEIN_show === 'on'
  },
  // pageId=1391863655
  showPaypalBtn(state, getters) {
    if (state.locals?.IS_RW ) return false
    let noLogin = !state.loginStatus
    let paypalCartSwitchStatus = state.cartAbtInfo?.paypalCartSwitch?.param?.paypalCartSwitchStatus || 0
    return noLogin && (paypalCartSwitchStatus == 2 || (!getters.payShowStyleA && paypalCartSwitchStatus == 1))
  },
  // 商品折扣是否以商品行折扣标签展示（TR-37526）
  isNewCartDiscountStyle(state, getters) {
    if (getters.isSuggested) return false // 合规站点走old逻辑
    return state.cartAbtInfo?.cartDiscountStyle?.param?.cart_discount_style === 'new'
  },
  isNewStoreIcon(state) {
    return state.cartAbtInfo?.storeiconchange?.param?.storeiconchange === 'new'
  },
  isNewReturnFlag(state) {
    return state.cartAbtInfo?.Cartrefund?.param?.cart_nonrefundable === 'on'
  },
  // 是否展示折扣倒计时及明细
  showCartPromotionsDetail(state) {
    return state.cartAbtInfo?.cartPromotions?.param?.cart_promotions_detail != 'off'
  },
  normalCartItems(state, getters) {
    return getters.cartList.filter(item => !state.soldoutIds.includes(item.id))
  },
  normalCartItemIds(state, getters) {
    return getters.cartList.filter(item => !state.soldoutIds.includes(item.id)).map((v) => v.id)
  },
  showDetailPriceCountDown(state) {
    return state.cartAbtInfo?.detailPriceCountDown?.param?.detailPrice_countDown === 'on'
  },
  cartcancelledTip(state) {
    return state.cartAbtInfo?.CancelledorderTip?.param?.cartcancelled_tip == 1
  },
  // 券助手与券权益、券凑单共存
  cartCouponEntranceLog(state) {
    return state.cartAbtInfo?.Cartcouponadd?.param?.cart_coupon_entrance_log === 'on'
  },
  // 凑单组件点击商品是否跳转商详
  cartAddTradeGoodsDetailSwitch(state) {
    return state.cartAbtInfo?.CartaddGoodsdetailSwitch?.param?.cartadd_trade_goodsdetail_switch === 'on'
  },
  // 自研服务的定制商品图片域名列表
  customServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.CUSTOM_SERVICE || ['ltwebstatic.com', 'shein.com', 'romwe.com']
  },
  // 第三方服务的定制商品图片域名列表
  thirdPartyServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.THIRD_PARTY_SERVICE || ['staticmeow.com/', 'customeow.io/', 'maiyuan.online/']
  },
  // 在权益条上是否展示free shipping
  showFreeShipping4RightsFuns(state, getters) {
    if((
      getters.newFreeShippingInfo?.freeShippinpTips
      && !getters.newFreeShippingInfo?.showShipSupplement
    )
      || (
        getters.topShippingInfo?.shippingTip
        && (
          !getters.topShippingInfo?.showShipSupplement 
          || getters.satisfyFreeShipping
        )
      )
    ) return true
    return false
  },
  // 免邮凑单模块新样式
  oldRightsFuns(state, getters) {
    return state.cartAbtInfo?.cartRightsFuns?.param?.cart_rights_funs === 'on' && (
      !state.isNewUserOfTsp
      || (
        state.isNewUserOfTsp
        && !getters.showFreeShipping4RightsFuns
      )
    )
  },
  // 是否开启新版优惠券组件
  isCartNewCouponList(state) {
    return state.cartAbtInfo?.CartNewCouponlist?.param?.cart_new_couponlist != 'off'
  },
  // 是否开启价格新样式
  isNewPrice(state, getters) {
    if (getters.isSuggested) return false // 合规站点走old逻辑
    return state.cartAbtInfo?.cartPriceStyle?.param?.cart_price_style === 'on'
  },
  // 购物车引导至推荐
  cartRecommendGuideConfig(state) {
    const { RecommendGuide = 'off', RecommendLine = 20, RecommendTime = 25 } = state.cartAbtInfo?.cartRecommendGuide?.param || {}
    return {
      style: RecommendGuide,       // off-无引导；A-气泡提示；B-提示插入商品行
      line: Number(RecommendLine), // 20行后再触发引导至推荐
      time: Number(RecommendTime)  // 25s后再触发引导至推荐
    }
  },
  // 购物车券筛选接csc平台
  couponFilterIsCsc(state) {
    return state.cartAbtInfo?.cartSelect?.param?.coupon_recommend == 'new_logic' && !state.cscCouponIsError
  },
  // 是否展示新的活动底部bar
  isShowNewPromoBar(state) {
    return ['planA', 'planB'].includes(state.cartAbtInfo?.promotionCouponMix?.param?.promotion_coupon_mix)
  },
  // 可切mall商品仍然放在失效商品组展示
  cartMallSwitchGoods(state) {
    return state.cartAbtInfo?.CartOOSGoodsPosition?.param?.cart_mall_switch_goods === 'on'
  },
  // 可切换其他颜色或尺码商品仍然放在失效商品组展示
  cartSaleAttrSwitchGoods(state) {
    return state.cartAbtInfo?.CartOOSGoodsPosition?.param?.cart_saleattr_switch_goods === 'on'
  },
  // 是否调整购物车主图尺寸并展示新的商品行标签样式（UG-101110）
  isNewCartrowVision(state) {
    return state.cartAbtInfo?.NewCartrowVision?.param?.new_cartrow_vision === 'on'
  },
  // 是否不在购物车商品行名称末端展示收藏按钮，并将收藏收入到左滑功能之中
  wishlistInLeftSliding(state) {
    return state.cartAbtInfo?.CartrowWishlistIcon?.param?.cartrow_wishlist_icon === 'on'
  },
  // 是否展示购物车权益
  showCartRights(state) {
    return  state.cartAbtInfo?.cartRightsFuns?.param?.cart_rights_funs == 'on'
  },
  // 是否开启老客权益展示, off-无免费退权益；planA-新增免费退，展示样式A；planB-新增免费退，展示样式B
  cartRightsForOldUser(state) {
    return state.cartAbtInfo?.cartRightsFreereturn?.param?.cart_rights_freereturn || 'off'
  },
  cartStoreFreegift(state) {
    return state.cartAbtInfo?.cartStoreFreeGift?.param?.cart_store_freegift == 'on'
  },
  // 购物车筛选信息承接
  showFilteredInformation(state) {
    return state.cartAbtInfo?.cartSelect?.param?.selectinform == 'on'
  },
  // 购物车楼层弹窗
  cartfloorInform(state) {
    return state.cartAbtInfo?.IncentiveInform?.param?.CartfloorInform === 'on'
  },
  // 购物车新客权益是否细分人群
  isClassifyNewUser(state) {
    return state.cartAbtInfo?.cartRightsFuns?.param?.cart_rights_funs == 'on'
      && state.cartAbtInfo?.cartRightsLabel?.param?.cart_rights_label == 'on'
  },
  lowinventory(state) {
    return state.cartAbtInfo?.outofstocktips?.param?.lowinventory == 'on'
  },
  isSheinClubMember(state) {
    return !!state.cartInfo?.promotion_member_info?.length
  }
}
export default getters
